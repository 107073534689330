<template>
  <div
    class="d-flex flex-wrap"
  >
    <page-header class="mb-4">
      {{ $t('openTime') }}
    </page-header>
    <div
      :style="!$vuetify.breakpoint.lgAndUp ? 'grid-template-columns: 1fr;' : 'grid-template-columns:0.7fr 1fr;'"
      style="display: grid;grid-gap: 48px"
    >
      <div>
        <template v-if="isLoading">
          <div
            :class="!$vuetify.breakpoint.lgAndUp ? '' : 'mb-8'"
            class="text-h4 font-weight-bold mt-4"
          >
            {{ $t('weekdays') }}
          </div>
          <v-card
            v-for="i in weekDayList"
            :key="i.id"
            class="mt-2 pa-2 px-4"
            elevation="0"
            @click="editOpenTime(i)"
          >
            <div class="d-flex align-center">
              <div>
                <div
                  :class="dayOfWeek===i.id?'font-weight-black':''"
                  class="text-body-1"
                >
                  {{ i.name }}
                </div>
                <div
                  v-if="reservationReady"
                  class="text-caption"
                >
                  {{ $t('defaultEatTime', { defaultTime: i.useTime }) }}
                </div>
              </div>
              <v-spacer/>
              <div>
                <div
                  v-for="x in i.openingTimespan"
                  :key="x.id"
                >
                  <div class="text-body-2">
                    {{ x.from }} - {{ x.to }}
                  </div>
                </div>
              </div>
              <div class="ml-4">
                <v-icon size="20">
                  mdi-chevron-right
                </v-icon>
              </div>
            </div>
          </v-card>
        </template>
        <template v-else>
          <div
            class="d-flex align-center justify-center"
            style="height: 400px"
          >
            <v-progress-circular
              indeterminate
              size="64"
            />
          </div>
        </template>
      </div>
      <template v-if="searchOpenTime">
        <new-add-form
          v-if="searchOpenTime"
          :day-of-week="dayOfWeek"
          :default-reserve-time="weekDayList"
          :show-list="openTimeList"
          @reload="reload"
        />
      </template>
      <template v-else>
        <div
          class="d-flex align-center justify-center"
          style="height: 400px"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import PageHeader from '@/composable/InK/typography/PageHeader'
  import { getReserveSettings, reservationReady } from '@/common/Utlis/api'
  import OpeningTime from '@/model/takeaway/OpeningTime'
  import NewAddForm from '@/views/AadenPages/settings/reserveSetting/fragment/NewAddForm'
  import WeekDays from '@/model/common/WeekDays'
  import dayjs from 'dayjs'

  export default {
    name: 'OpenTime',
    components: { NewAddForm, PageHeader },
    data: function () {
      return {
        weekDayList: [],
        searchOpenTime: false,
        dayOfWeek: 1,
        openTimeList: [],
        isLoading: false,
        tab: null,
        time: null,
        datePick: false,
        openDateTime: null,
        reservationReady: false,
      }
    },
    async mounted () {
      await this.reload()
    },
    methods: {
      editOpenTime (item) {
        this.searchOpenTime = false
        this.dayOfWeek = item.id
        this.searchOpenTime = true
      },
      async reload () {
        this.reservationReady = await reservationReady()
        this.isLoading = false
        this.searchOpenTime = false
        const res = (await getReserveSettings()).weeklySettings.sort(function (a, b) {
          return a.dayOfWeek - b.dayOfWeek
        })
        this.openTimeList = (await OpeningTime.getList(true))
        this.searchOpenTime = true
        this.isLoading = true
        this.openDateTime = res.map(it => {
          it.dayOfWeek = parseInt(it.dayOfWeek)
          it.weekDay = this.weekDayList.find(item => item.id === it.dayOfWeek)
          it.useTime = dayjs.duration(it.defaultEstimateDiningTime).as('hours')
          return it
        })
        this.weekDayList = []

        const weekday = WeekDays.getList()
        weekday.forEach(it => {
          const res = this.openDateTime.find(x => x.dayOfWeek === it.id)
          this.weekDayList.push({ ...it, ...res })
        })
      },
    },
  }
</script>
